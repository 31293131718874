@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  -ms-overflow-style: none !important;
}

* {
  scrollbar-width: none !important;
  outline: none;
}

canvas {
  outline: none;
}
